import { AriaLiveErrorMessage } from "../../AriaLive/AriaLiveErrorMessage"
import {
  Button,
  Card,
  InlineNotification,
  TextField,
  VisuallyHidden,
} from "@kaizen/components"
import { GoogleSignIn } from "../../Button/GoogleSignIn"
import { Icon } from "@kaizen/components/future"
import { SignInParams } from "../../../types/Login/account-login-form"
import { Text } from "@kaizen/components"
import { UserAuthData } from "../../../types/storage"
import {
  appendFakeSubdomainIfNeeded,
  extractRegionalDomain,
  getEnvironmentName,
  getMurmurResetPasswordUrl,
  getMurmurSSOUrl,
  isText,
} from "../../../utils"
import { determineEnvironment } from "@cultureamp/frontend-env"
import { getCookie } from "cookies-next"
import { setStorage } from "../../../utils/storage"
import { signInErrorToastNotification } from "../../Notification/ToastNotification"
import { useCsrfTokenContext } from "../../../providers"
import { useQueryParam } from "../../../hooks"
import Link from "next/link"
import React, { useEffect, useRef, useState } from "react"
import styles from "../Form.module.scss"

export interface Props {
  hasPersistedData: boolean
  availableLoginOptions: string[]
  workEmail: string
  applicationId: string
  subdomain: string
  displayUnrecognisedLoginError: boolean
  unrecognisedLoginErrorCount: number
  handleGoBack: () => void
}

export const AccountLoginForm: React.FC<Props> = ({
  hasPersistedData = false,
  availableLoginOptions,
  workEmail,
  applicationId,
  subdomain,
  displayUnrecognisedLoginError,
  unrecognisedLoginErrorCount,
  handleGoBack,
}) => {
  const { csrfToken, isLoading } = useCsrfTokenContext()
  const fakeSubdomain = useQueryParam("fake-subdomain")
  const [storageEnvHint, setStorageEnvHint] = useState<string>()

  const [showPasswordSignIn, setShowPasswordSignIn] = useState<boolean>(false)
  const [showGoogleSignIn, setShowGoogleSignIn] = useState<boolean>(true)
  const [showSamlSignIn, setShowSamlSignIn] = useState<boolean>(false)
  const [password, setPassword] = useState<string>("")
  const [showUnrecognisedLoginError, setShowUnrecognisedLoginError] =
    useState<boolean>(displayUnrecognisedLoginError)
  const [unrecognisedLoginErrorCounter, setUnrecognisedLoginErrorCounter] =
    useState<number>(unrecognisedLoginErrorCount)
  const [resetPasswordUrl, setResetPasswordUrl] = useState<string>("")
  const [ssoUrl, setSSOUrl] = useState<string>("")
  const [showInvalidPasswordError, setShowInvalidPasswordError] =
    useState<boolean>(false)
  const [invalidPasswordErrorCounter, setInvalidPasswordErrorCounter] =
    useState<number>(0)
  const [signInButtonDisabled, setSignInButtonDisabled] =
    useState<boolean>(false)

  const textFieldRef = useRef<HTMLInputElement | null>(null)
  const notificationRef = useRef<HTMLDivElement | null>(null)

  const passwordErrorMessageTitle = "We don’t recognise that login"
  const passwordErrorMessage =
    "Check that your email and password are entered correctly and try again."

  useEffect(() => {
    if (determineEnvironment().realm !== "production") {
      setStorageEnvHint(getEnvironmentName())
    } else {
      setStorageEnvHint(extractRegionalDomain(window.location.host))
    }
  }, [])

  useEffect(() => {
    if (showUnrecognisedLoginError && notificationRef.current) {
      notificationRef.current.focus()
    }
  }, [showUnrecognisedLoginError])

  useEffect(() => {
    setResetPasswordUrl(
      getMurmurResetPasswordUrl(
        window.location.protocol,
        window.location.hostname,
      ),
    )
  }, [])

  useEffect(() => {
    setSSOUrl(
      getMurmurSSOUrl(
        window.location.protocol,
        window.location.hostname,
        subdomain,
        getCookie("redirect_to") as string,
      ),
    )
  }, [subdomain])

  useEffect(() => {
    setShowPasswordSignIn(availableLoginOptions.includes("email"))
    setShowGoogleSignIn(availableLoginOptions.includes("googleOauth"))
    setShowSamlSignIn(availableLoginOptions.includes("saml"))
  }, [availableLoginOptions])

  const isGoogleButtonPrimary = (availableLoginOptions: string[]) => {
    if (
      availableLoginOptions.length === 1 &&
      availableLoginOptions.includes("googleOauth")
    ) {
      return true
    }

    return (
      availableLoginOptions.length === 2 &&
      availableLoginOptions.includes("googleOauth") &&
      availableLoginOptions.includes("saml")
    )
  }

  const isSamlButtonPrimary = (availableLoginOptions: string[]) => {
    return (
      availableLoginOptions.length === 1 &&
      availableLoginOptions.includes("saml")
    )
  }

  const unrecognisedLoginSetter = (
    value: boolean,
    setShowUnrecognisedLoginError: React.Dispatch<
      React.SetStateAction<boolean>
    >,
  ) => {
    setShowUnrecognisedLoginError(value)
  }

  const handlePasswordSignIn = (signInParams: SignInParams) => async () => {
    const {
      setShowUnrecognisedLoginError,
      subdomain,
      applicationId,
      workEmail,
      password,
      setUnrecognisedLoginErrorCounter,
      textFieldRef,
      csrfToken,
      setShowInvalidPasswordError,
      setInvalidPasswordErrorCounter,
      fakeSubdomain,
    } = signInParams

    setSignInButtonDisabled(true)

    unrecognisedLoginSetter(false, setShowUnrecognisedLoginError)
    setShowInvalidPasswordError(false)

    if (!isText(password)) {
      setShowInvalidPasswordError(true)
      setInvalidPasswordErrorCounter(prevCounter => prevCounter + 1)
      textFieldRef.current?.focus()
      setSignInButtonDisabled(false)
      return
    }

    let apiEndpoint = "/app/auth/api/password-login"
    apiEndpoint = appendFakeSubdomainIfNeeded(apiEndpoint, fakeSubdomain)

    const response = await fetch(apiEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        applicationId: applicationId,
        loginId: workEmail,
        password: password,
        subdomain: subdomain,
        csrfToken,
      }),
    }).then(response => {
      unrecognisedLoginSetter(false, setShowUnrecognisedLoginError)
      // we decided to return 404 for unauthorized requests to avoid leaking information
      if (response.status == 404) {
        setUnrecognisedLoginErrorCounter(prevCounter => prevCounter + 1)
        unrecognisedLoginSetter(true, setShowUnrecognisedLoginError)
      } else if (response.status == 500) {
        signInErrorToastNotification()
        textFieldRef.current?.focus()
      }

      return response.json()
    })

    if (response.status === "success") {
      setStorage<UserAuthData>("user-auth-data", {
        applicationId,
        availableLoginOptions,
        environment: getEnvironmentName(),
        subdomain,
        workEmail,
        createdAt: Date.now(),
      })

      window.location.href = `${response.data.redirectUrl}`
    }
    setSignInButtonDisabled(false) //
  }

  if (isLoading || !csrfToken) {
    return null
  }

  const signInParams: SignInParams = {
    setShowUnrecognisedLoginError,
    subdomain,
    applicationId,
    workEmail,
    password,
    setUnrecognisedLoginErrorCounter,
    textFieldRef,
    csrfToken,
    setShowInvalidPasswordError,
    setInvalidPasswordErrorCounter,
    fakeSubdomain,
  }

  return (
    <>
      <Card classNameOverride={styles.form}>
        <div className="p-24">
          {showUnrecognisedLoginError && (
            <div tabIndex={-1} ref={notificationRef}>
              <InlineNotification
                title={passwordErrorMessageTitle}
                variant="warning"
                persistent
                hideCloseIcon
              >
                {passwordErrorMessage + " "}
                {showPasswordSignIn && (
                  <Link
                    href={{
                      pathname: resetPasswordUrl,
                    }}
                    legacyBehavior={false}
                    aria-label="Can't sign in? Recover your account here."
                  >
                    Need help signing in?
                  </Link>
                )}
                <VisuallyHidden>
                  {unrecognisedLoginErrorCounter > 0 && (
                    <AriaLiveErrorMessage
                      counter={unrecognisedLoginErrorCounter}
                      message={`${passwordErrorMessageTitle}. ${passwordErrorMessage}`}
                    />
                  )}
                </VisuallyHidden>
              </InlineNotification>
            </div>
          )}

          <div className="mt-8 mb-16">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <a
              href="#"
              onClick={handleGoBack}
              className={styles.topLink}
              aria-label="Go back to choose a different email"
            >
              ← Choose a different email
            </a>
          </div>

          <div className="mt-16 mb-16">
            <dl>
              <dt>Work email</dt>
              <dd>
                <Text variant="body">{workEmail}</Text>
              </dd>
            </dl>
          </div>

          {showPasswordSignIn && (
            <>
              <TextField
                id="password"
                inputRef={textFieldRef}
                type="password"
                labelText="Password"
                icon={<Icon name="key" isPresentational isFilled />}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(event.target.value)
                }}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    event.preventDefault()
                    handlePasswordSignIn(signInParams)()
                  }
                }}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                status={showInvalidPasswordError ? "error" : "default"}
                validationMessage={
                  showInvalidPasswordError
                    ? "Please enter a valid password"
                    : ""
                }
              />

              <VisuallyHidden>
                {invalidPasswordErrorCounter > 0 && (
                  <AriaLiveErrorMessage
                    counter={invalidPasswordErrorCounter}
                    message="Please enter a valid password"
                  />
                )}
              </VisuallyHidden>

              <div className="mb-12 mt-24">
                <Button
                  label="Sign In"
                  aria-label="Sign in with email"
                  primary
                  fullWidth
                  onClick={handlePasswordSignIn(signInParams)}
                  disabled={signInButtonDisabled}
                />
              </div>
            </>
          )}

          {showGoogleSignIn && (
            <GoogleSignIn
              isPrimary={isGoogleButtonPrimary(availableLoginOptions)}
            />
          )}

          {showSamlSignIn && (
            <div className="mb-12">
              <Button
                label="Sign in with SSO"
                primary={isSamlButtonPrimary(availableLoginOptions)}
                fullWidth
                href={ssoUrl}
              />
            </div>
          )}

          {hasPersistedData && (
            <div className="mt-4 mb-16 text-center">
              <Text
                variant="small"
                color="dark-reduced-opacity"
                classNameOverride="subdomain"
              >
                Signing in to: <b>{subdomain}</b>
                {storageEnvHint ? `.${storageEnvHint}` : ""}
              </Text>
            </div>
          )}

          {showPasswordSignIn && (
            <div className="p-6">
              <Link
                href={{
                  pathname: resetPasswordUrl,
                }}
                legacyBehavior={false}
                className={styles.bottomLink}
                aria-label="Can't sign in? Recover your account here."
              >
                Can't sign in?
              </Link>
            </div>
          )}
        </div>
      </Card>
    </>
  )
}
