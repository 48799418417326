import { EXPIRY_DAYS } from "../constants"
import { PersistedKey, StorageData } from "../types/storage"

/**
 * Adds day(s) to a time value
 *
 * @param days Number of days
 * @param time Time value in milliseconds
 * @returns Time value in milliseconds with added number of days
 */
export function addDays(days: number, time = Date.now()) {
  const date = new Date(time)
  date.setUTCDate(date.getUTCDate() + days)

  return date.getTime()
}

/**
 * Sets client-side data to the local storage
 */
export function setStorage<T>(key: PersistedKey, data: T) {
  try {
    window.localStorage?.setItem(key, JSON.stringify(data))
  } catch (e) {
    console.error(e)
  }
}

/**
 * Removes the key from the local storage
 */
export function removeStorage(key: PersistedKey) {
  try {
    window.localStorage?.removeItem(key)
  } catch (e) {
    console.error(e)
  }
}

/**
 * Retrieves the persisted data from the local storage
 */
export function getStorage<T extends StorageData>(key: PersistedKey): T | null {
  try {
    const persistedData = window.localStorage?.getItem(key)

    if (persistedData) {
      const parsedData = JSON.parse(persistedData) as T
      const { createdAt } = parsedData

      if (Date.now() >= addDays(EXPIRY_DAYS, createdAt)) {
        removeStorage(key)
        return null
      }

      return parsedData
    }
  } catch (error) {
    console.error(error)
  }

  return null
}
